import { useEffect, useRef, useState } from "react";
import { use100vh } from "react-div-100vh";
import useSound from "use-sound";
import "./App.css";

import noti from "./sound.mp3";

function App() {
  const height = use100vh();

  // for sound
  const [play, { stop }] = useSound(noti);
  const [playCounter, setPlayCounter] = useState(0);

  // for image
  const [white, setWhite] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [imgCounter, setImgCounter] = useState(0);

  useInterval(
    () => {
      setWhite(!white);
    },
    isRunning ? 100 : null
  );

  useInterval(
    () => {
      setImgCounter(imgCounter === 3 ? 1 : imgCounter + 1);
    },
    isRunning ? 500 : null
  );

  const action = () => {
    if (playCounter === 0) {
      play();
      setIsRunning(true);
      setPlayCounter(1);
    } else if (playCounter === 3) {
      stop();
      setPlayCounter(0);
      setIsRunning(false);
      setImgCounter(0);
      setWhite(true);
    } else {
      setPlayCounter(playCounter + 1);
    }
  };

  return (
    <div
      className="App"
      style={{ height, backgroundImage: `url('/dennis/dennis_${imgCounter}.jpg')` }}
      onClick={action}
    >
      <h1 className={white ? "white" : "black"}>
        {isRunning ? (
          <>
            FICK
            <br />
            NICHT
            <br />
            DEN
            <br />
            FICKER
          </>
        ) : (
          <>
            CLICK
            <br />
            TO
            <br />
            PLAY
          </>
        )}
      </h1>
    </div>
  );
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default App;
